import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [rol, setRol] = useState(0); // Initialize rol with a default value

  useEffect(() => {
    // Check if username and rol are saved in localStorage (after login)
    const storedUsername = localStorage.getItem('username');
    const storedRol = localStorage.getItem('rol'); // Retrieve role from localStorage

    if (storedUsername) {
      setUsername(storedUsername);
    }

    if (storedRol) {
      setRol(parseInt(storedRol, 10)); // Convert stored role to an integer
    }
  }, []);

  const updateUser = (newUsername, newRol) => {
    setUsername(newUsername);
    setRol(newRol);

    localStorage.setItem('username', newUsername);
    localStorage.setItem('rol', newRol); // Save role to localStorage
  };

  return (
    <UserContext.Provider value={{ username, rol, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
