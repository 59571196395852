import React from 'react';
import { Table, Container, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext';

class WellnessReport extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const now = new Date();
    now.setHours(0, 0, 0, 0); // Set time to 00:00:00 in local time
    this.state = {
      reportData: [],
      teams: [],
      selectedTeam: null,
      date: now, // Ensure date starts with 00:00:00 local time
    };
  }

  componentDidMount() {
    this.fetchTeams();
  }

  fetchTeams = async () => {
    try {
      const response = await axios.get('/api/roster/teams');
      if (response.data.success && response.data.data.length > 0) {
        const teams = response.data.data;
        const firstTeam = teams[0];

        this.setState(
          { teams, selectedTeam: firstTeam.id_plantilla },
          () => this.fetchReportData(firstTeam.id_plantilla, this.state.date)
        );
      } else {
        console.error('Error fetching teams');
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  fetchReportData = async (teamId, date) => {
    const formattedDate = date.toISOString().split('T')[0];
    try {
      const response = await axios.get(`/api/wellness/getWellnessReport/${teamId}/${formattedDate}`);
      if (response.data.success) {
        this.setState({ reportData: response.data.data });
      } else {
        console.error('Error fetching report data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  handleTeamChange = (event) => {
    const selectedTeam = event.target.value;
    this.setState({ selectedTeam }, () => {
      this.fetchReportData(selectedTeam, this.state.date);
    });
  };

  handleDateChange = (date) => {
    const localDate = new Date(date);
    localDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 in local time
    this.setState({ date: localDate }, () => {
      this.fetchReportData(this.state.selectedTeam, localDate);
    });
  };

  getLabelAndColor(value, type) {
    const options = {
      preTrainingMood: [
        { value: 1, label: 'Triste' },
        { value: 2, label: 'Desmotivado' },
        { value: 3, label: 'Normal' },
        { value: 4, label: 'Motivado' },
        { value: 5, label: 'Alegre' },
      ],
      sleepQuality: [
        { value: 1, label: 'Muy Malo' },
        { value: 2, label: 'Malo' },
        { value: 3, label: 'Regular' },
        { value: 4, label: 'Bueno' },
        { value: 5, label: 'Muy Bueno' },
      ],
      stressLevel: [
        { value: 5, label: 'Muy bajo' },
        { value: 4, label: 'Bajo' },
        { value: 3, label: 'Normal' },
        { value: 2, label: 'Alto' },
        { value: 1, label: 'Muy alto' },
      ],
      muscleFatigue: [
        { value: 5, label: 'Muy bajo' },
        { value: 4, label: 'Bajo' },
        { value: 3, label: 'Normal' },
        { value: 2, label: 'Alto' },
        { value: 1, label: 'Muy alto' },
      ],
      musclePain: [
        { value: 5, label: 'Muy bajo' },
        { value: 4, label: 'Bajo' },
        { value: 3, label: 'Normal' },
        { value: 2, label: 'Alto' },
        { value: 1, label: 'Muy alto' },
      ],
      hydration: [
        { value: 1, label: 'Hidratado' },
        { value: 2, label: 'Deshidratado' },
        { value: 3, label: 'Severamente Deshidratado' },
      ],
    };

    const selectedOption = options[type].find(option => option.value === value);
    const color = type === 'hydration' ? this.getHydrationColor(value) : this.getColorForValue(value);

    return {
      label: selectedOption ? selectedOption.label : 'N/A',
      color,
    };
  }

  getColorForValue(value) {
    const colors = ["#ff0000", "#ff7f00", "#ffff00", "#7fff00", "#00ff00"];
    return colors[value - 1] || 'transparent';
  }

  getHydrationColor(value) {
    const hydrationColors = ["#e4e500", "#e2a300", "#5b3b00"];
    return hydrationColors[value - 1] || 'transparent';
  }

  render() {
    const { username } = this.context;
    const { reportData, teams, selectedTeam, date } = this.state;

    return (
      <MainLayout>
        <Container>
          <h4>Hola, {username}</h4>
          <p>Consulta el reporte de Wellness</p>

          {/* Team and Date Filters */}
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="teamSelect">
                  <Form.Label>Seleccione un equipo</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedTeam || ''}
                    onChange={this.handleTeamChange}
                  >
                    {teams.map(team => (
                      <option key={team.id_plantilla} value={team.id_plantilla}>
                        {team.nombre_plantilla}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="datePicker">
                  <Form.Label>Seleccione una fecha</Form.Label><br />
                  <DatePicker
                    selected={date}
                    onChange={this.handleDateChange}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          {/* Wellness Report Table */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Peso</th>
                <th>Ánimo</th>
                <th>Calidad de Sueño</th>
                <th>Nivel de Estrés</th>
                <th>Fatiga Muscular</th>
                <th>Dolor Muscular</th>
                <th>Hidratación</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(player => (
                <tr key={player.id_usuario}>
                  <td>{player.dorsal}</td>
                  <td>{`${player.nombres_usuario} ${player.apellidos_usuario}`}</td>
                  <td>{player.peso || 'N/A'}</td>
                  <td>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getLabelAndColor(player.animo, 'preTrainingMood').color,
                        marginRight: '5px',
                      }}
                    ></span>
                    {this.getLabelAndColor(player.animo, 'preTrainingMood').label}
                  </td>
                  <td>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getLabelAndColor(player.sueno, 'sleepQuality').color,
                        marginRight: '5px',
                      }}
                    ></span>
                    {this.getLabelAndColor(player.sueno, 'sleepQuality').label}
                  </td>
                  <td>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getLabelAndColor(player.estres, 'stressLevel').color,
                        marginRight: '5px',
                      }}
                    ></span>
                    {this.getLabelAndColor(player.estres, 'stressLevel').label}
                  </td>
                  <td>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getLabelAndColor(player.fatiga, 'muscleFatigue').color,
                        marginRight: '5px',
                      }}
                    ></span>
                    {this.getLabelAndColor(player.fatiga, 'muscleFatigue').label}
                  </td>
                  <td>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getLabelAndColor(player.dolor_muscular, 'musclePain').color,
                        marginRight: '5px',
                      }}
                    ></span>
                    {this.getLabelAndColor(player.dolor_muscular, 'musclePain').label}
                  </td>
                  <td>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getLabelAndColor(player.hidratacion, 'hydration').color,
                        marginRight: '5px',
                      }}
                    ></span>
                    {this.getLabelAndColor(player.hidratacion, 'hydration').label}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </MainLayout>
    );
  }
}

export default WellnessReport;
