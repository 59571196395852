import React from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

const PlayerCreateModal = ({
  show,
  onHide,
  onSubmit,
  onInputChange,
  playerData,
  positions,
  countries,
  showPassword,
  toggleShowPassword,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Nuevo Jugador</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formUsername">
                <Form.Label>Login</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese Login"
                  name="username"
                  value={playerData.username || ''}
                  onChange={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Ingrese un password"
                  name="password"
                  value={playerData.password || ''}
                  onChange={onInputChange}
                />
                <Form.Check
                  type="checkbox"
                  label="Mostrar password"
                  checked={showPassword}
                  onChange={toggleShowPassword}
                  className="mt-2"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formNombres">
                <Form.Label>Nombres</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el nombre del jugador"
                  name="nombres"
                  value={playerData.nombres || ''}
                  onChange={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formApellidos">
                <Form.Label>Apellidos</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese los apellidos del jugador"
                  name="apellidos"
                  value={playerData.apellidos || ''}
                  onChange={onInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formEstatura" className="mt-3">
                <Form.Label>Estatura (cm)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese estatura en cm"
                  name="estatura"
                  value={playerData.estatura || ''}
                  onChange={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPeso" className="mt-3">
                <Form.Label>Peso (kg)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingrese el peso del jugador en Kg"
                  name="peso"
                  value={playerData.peso || ''}
                  onChange={onInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="formFechaNacimiento" className="mt-3">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_nacimiento"
                  value={playerData.fecha_nacimiento || ''}
                  onChange={onInputChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPosicion" className="mt-3">
                <Form.Label>Posición</Form.Label>
                <Form.Control
                  as="select"
                  name="posicion"
                  value={playerData.posicion || ''}
                  onChange={onInputChange}
                >
                  <option value="">Seleccione posición</option>
                  {positions.map((pos) => (
                    <option key={pos.code} value={pos.code}>
                      {pos.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="formNacionalidad" className="mt-3">
            <Form.Label>Nacionalidad</Form.Label>
            <Form.Control
              as="select"
              name="nacionalidad"
              value={playerData.nacionalidad || ''}
              onChange={onInputChange}
            >
              <option value="">Seleccione Nacionalidad</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name} - {country.code}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3">
            Agregar Jugador
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PlayerCreateModal;
