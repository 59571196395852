import React from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table } from 'react-bootstrap';

class AssignPlayerModal extends React.Component {
  state = {
    searchQuery: '',
    players: [],
    error: null,
    shirtNumbers: {}, // Stores shirt numbers keyed by player ID
    weights: {}, // Stores weights keyed by player ID
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSearchPlayers = () => {
    const { searchQuery } = this.state;
    const token = localStorage.getItem('auth-token');

    axios.get(`/api/roster/players/search?query=${searchQuery}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        this.setState({ players: response.data.data, error: null });
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to fetch players' });
      });
  };

  handleAssignPlayer = (playerId) => {
    const { selectedTeam, onPlayerAssigned } = this.props;
    const { shirtNumbers, weights } = this.state;
    const token = localStorage.getItem('auth-token');

    const shirtNumber = shirtNumbers[playerId] || '';
    const weight = weights[playerId] || '';

    axios.post(`/api/roster/teams/${selectedTeam.id_plantilla}/add-player`, 
      { id_usuario: playerId, dorsal: shirtNumber, peso: weight },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        alert('Player assigned to team successfully.');
        onPlayerAssigned(); // Callback to refresh the team data
        this.props.onHide(); // Close the modal
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to assign player' });
      });
  };

  handleInputChange = (playerId, field, value) => {
    this.setState((prevState) => ({
      [field]: {
        ...prevState[field],
        [playerId]: value,
      },
    }));
  };

  render() {
    const { show, onHide } = this.props;
    const { searchQuery, players, error, shirtNumbers, weights } = this.state;

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar jugador a plantilla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Form>
            <Form.Group controlId="formSearchPlayer">
              <Form.Label>Buscar jugador, deje en blanco para mostrar todos y haga click en buscar</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese nombre o apellido, deje en blanco para buscar todos"
                value={searchQuery}
                onChange={this.handleSearchChange}
              />
            </Form.Group>
            <Button variant="primary" onClick={this.handleSearchPlayers} className="mt-3">
              Buscar
            </Button>
          </Form>

          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Posición</th>
                <th># Dorsal</th>
                <th>Peso</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {players.map((player) => (
                <tr key={player.id_usuario}>
                  <td>{player.id_usuario}</td>
                  <td>{player.apellidos_usuario}, {player.nombres_usuario}</td>
                  <td>{player.posicion}</td>
                  <td>
                    <Form.Control
                      type="number"
                      placeholder="Numero de dorsal"
                      value={shirtNumbers[player.id_usuario] || ''}
                      onChange={(e) => this.handleInputChange(player.id_usuario, 'shirtNumbers', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      placeholder="Peso (kg)"
                      value={weights[player.id_usuario] || ''}
                      onChange={(e) => this.handleInputChange(player.id_usuario, 'weights', e.target.value)}
                    />
                  </td>
                  <td>
                    <Button 
                      variant="success" 
                      size="sm" 
                      onClick={() => this.handleAssignPlayer(player.id_usuario)}
                    >
                      +
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AssignPlayerModal;
