import React from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';

class PlayerUpdateModal extends React.Component {
    
  render() {
    const {
      show,
      onHide,
      onSubmit,
      playerData,
      positions,
      countries,
      onInputChange,
      showPassword,
      toggleShowPassword,
    } = this.props;


    
    const {
      username,
      password,
      nombres_usuario,
      apellidos_usuario,
      estatura_usuario,
      rol,
      peso_usuario,
      fecha_nacimiento,
      nacionalidad_usuario,
      posicion,
    } = playerData;

    console.log(playerData);

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Jugador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formUsername">
                  <Form.Label>Login</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese Login"
                    name="username"
                    value={username}
                    onChange={onInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Ingrese un password"
                    name="password"
                    value={password}
                    onChange={onInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Mostrar password"
                    checked={showPassword}
                    onChange={toggleShowPassword}
                    className="mt-2"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formNombres">
                  <Form.Label>Nombres</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el nombre del jugador"
                    name="nombres"
                    value={nombres_usuario}
                    onChange={onInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formApellidos">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese los apellidos del jugador"
                    name="apellidos"
                    value={apellidos_usuario}
                    onChange={onInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formEstatura" className="mt-3">
                  <Form.Label>Estatura (cm)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese estatura en cm"
                    name="estatura"
                    value={estatura_usuario}
                    onChange={onInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPeso" className="mt-3">
                  <Form.Label>Peso (kg)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese el peso del jugador en Kg"
                    name="peso"
                    value={peso_usuario}
                    onChange={onInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
              <Form.Group controlId="formFechaNacimiento" className="mt-3">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                    type="date"
                    name="fecha_nacimiento"
                    value={fecha_nacimiento} // Ensure this is in YYYY-MM-DD format
                    onChange={onInputChange}
                />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPosicion" className="mt-3">
                  <Form.Label>Posición</Form.Label>
                  <Form.Control
                    as="select"
                    name="posicion"
                    value={posicion}
                    onChange={onInputChange}
                  >
                    <option value="">Seleccione posición</option>
                    {positions.map((pos) => (
                      <option key={pos.code} value={pos.code}>
                        {pos.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formNacionalidad" className="mt-3">
              <Form.Label>Nacionalidad</Form.Label>
              <Form.Control
                as="select"
                name="nacionalidad"
                value={nacionalidad_usuario}
                onChange={onInputChange}
              >
                <option value="">Seleccione Nacionalidad</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} - {country.code}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Guardar Cambios
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PlayerUpdateModal;