import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';
import { Container, Row, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MainLayout from './Layouts/MainLayout'; // Assuming you're using MainLayout for structure
import { UserContext } from '../UserContext';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

class WellnessStats extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      teams: [],
      selectedTeam: null,
      date: new Date(),
      totalResponses: 0,
    };
  }

  componentDidMount() {
    this.fetchTeams();
  }

  fetchTeams = async () => {
    try {
      const response = await axios.get('/api/roster/teams');
      if (response.data.success && response.data.data.length > 0) {
        const teams = response.data.data;
        const firstTeam = teams[0];

        this.setState(
          { teams, selectedTeam: firstTeam.id_plantilla },
          () => this.fetchWellnessStats(firstTeam.id_plantilla, this.state.date)
        );
      } else {
        console.error('Error fetching teams');
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  fetchWellnessStats = async (teamId, date) => {
    const formattedDate = date.toISOString().split('T')[0];
    try {
      const response = await axios.get(
        `https://wellness.cdfas.com.sv/api/stats/getWellnessStats/${teamId}/${formattedDate}`
      );
      if (response.data.success) {
        this.setState({ data: response.data.data[0], totalResponses: response.data.data[0].tot_respuestas });
      } else {
        console.error('Error fetching wellness stats');
      }
    } catch (error) {
      console.error('Error fetching wellness stats:', error);
    }
  };

  handleTeamChange = (event) => {
    const selectedTeam = event.target.value;
    this.setState({ selectedTeam }, () => {
      this.fetchWellnessStats(selectedTeam, this.state.date);
    });
  };

  handleDateChange = (date) => {
    this.setState({ date }, () => {
      this.fetchWellnessStats(this.state.selectedTeam, date);
    });
  };

  processDataForChart = (prefix, labels) => {
    const { data } = this.state;
    if (!data) return { labels, datasets: [] };
  
    const values = labels.map((label) => parseInt(data[`${prefix}_${label}`] || 0, 10));
    const total = values.reduce((sum, value) => sum + value, 0);
  
    const percentages = values.map((value) => ((value / total) * 100).toFixed(1)); // Calculate percentages
  
    return {
      labels: labels.map((label, index) => `${label}: ${percentages[index]}%`), // Add percentages to labels
      datasets: [
        {
          data: values,
          backgroundColor: ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff'],
          hoverBackgroundColor: ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff'],
        },
      ],
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              color: '#ffffff', // Ensure text is white
              font: {
                size: 12, // Optional: adjust font size
                weight: 'bold', // Optional: make text bold
              },
              usePointStyle: true, // Use dots instead of rectangles
              pointStyle: 'circle', // Specify the shape of the point
              padding: 20, // Optional: add spacing for better readability
            },
          },
        },
      },
    };
  };
  
  

  render() {
    const { username } = this.context;
    const { teams, selectedTeam, date } = this.state;

    const questions = {
      ea: {name: "Estado de Ánimo", options: ['triste', 'desmotivado', 'normal', 'motivado', 'alegre'] },
      es: {name: "Estado de Sueño", options:['muy_malo', 'malo', 'regular', 'bueno', 'muy_bueno'] },
      ee: {name: "Nivel de Estrés", options:['muy_alto', 'alto', 'normal', 'bajo', 'muy_bajo'] },
      ef: {name: "Nivel de Fatiga", options:['muy_alto', 'alto', 'normal', 'bajo', 'muy_bajo']},
      ed: {name: "Nivel de Dolor Muscular", options:['muy_alto', 'alto', 'normal','bajo', 'muy_bajo']},
      eh: {name: "Estado de Hidratación", options:['muy_deshidratado', 'deshidratado', 'hidratado']},
    };

    return (
      <MainLayout>
        <Container>
          <h4>Hola, {username}</h4>
          <p>Consulta los gráficos de bienestar del equipo</p>

          {/* Filters */}
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="teamSelect">
                  <Form.Label>Seleccione un equipo</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedTeam || ''}
                    onChange={this.handleTeamChange}
                  >
                    {teams.map((team) => (
                      <option key={team.id_plantilla} value={team.id_plantilla}>
                        {team.nombre_plantilla}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="datePicker">
                  <Form.Label>Seleccione una fecha</Form.Label><br />
                  <DatePicker
                    selected={date}
                    onChange={this.handleDateChange}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md={12}>
              <h2>Total de respuestas: {this.state.totalResponses}</h2>
            </Col>
          </Row>
          {/* Pie Charts */}
          <Row>
            {Object.keys(questions).map((key) => (
              <Col md={6} sm={12} lg={4} className="mb-6 mt-6" key={key}>
                <h5>{questions[key].name.toUpperCase()}</h5>
                <Pie className='mb-3'
                
                data={this.processDataForChart(key, questions[key].options)}
                options={this.processDataForChart(key, questions[key].options).options}
                
                />
              </Col>
            ))}
          </Row>
        </Container>
      </MainLayout>
    );
  }
}

export default WellnessStats;
