import React from 'react';
import { Table, Container, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext';

class TrainingReport extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const now = new Date();
    now.setHours(0, 0, 0, 0); // Set time to 00:00:00 in local time
    this.state = {
      reportData: [],
      teams: [],
      selectedTeam: null,
      date: now, // Ensure date starts with 00:00:00 local time
    };
  }

  componentDidMount() {
    this.fetchTeams();
  }

  fetchTeams = async () => {
    try {
      const response = await axios.get('/api/roster/teams');
      if (response.data.success && response.data.data.length > 0) {
        const teams = response.data.data;
        const firstTeam = teams[0];

        this.setState(
          { teams, selectedTeam: firstTeam.id_plantilla },
          () => this.fetchReportData(firstTeam.id_plantilla, this.state.date)
        );
      } else {
        console.error('Error fetching teams');
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  fetchReportData = async (teamId, date) => {
    const formattedDate = date.toISOString().split('T')[0];
    try {
      const response = await axios.get(`/api/training/getTrainingReport/${teamId}/${formattedDate}`);
      if (response.data.success) {
        this.setState({ reportData: response.data.data });
      } else {
        console.error('Error fetching report data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  handleTeamChange = (event) => {
    const selectedTeam = event.target.value;
    this.setState({ selectedTeam }, () => {
      this.fetchReportData(selectedTeam, this.state.date);
    });
  };

  handleDateChange = (date) => {
    const localDate = new Date(date);
    localDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 in local time
    this.setState({ date: localDate }, () => {
      this.fetchReportData(this.state.selectedTeam, localDate);
    });
  };

  getRpeColor(value) {
    const colors = [
      "#00ff00", // 1 - Green
      "#7fff00", // 2
      "#bfff00", // 3
      "#ffff00", // 4 - Yellow
      "#ffbf00", // 5
      "#ff8000", // 6
      "#ff4000", // 7
      "#ff0000", // 8 - Red
      "#bf0000", // 9
      "#800000", // 10 - Dark Red
    ];
    return colors[value - 1] || 'transparent';
  }

  render() {
    const { username,rol } = this.context;



    const { reportData, teams, selectedTeam, date } = this.state;

    return (
      <MainLayout>
        <Container>
          <h4>Hola, {username}</h4>
          <p>Consulta el reporte de Entrenamiento</p>

          {/* Team and Date Filters */}
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="teamSelect">
                  <Form.Label>Seleccione un equipo</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedTeam || ''}
                    onChange={this.handleTeamChange}
                  >
                    {teams.map(team => (
                      <option key={team.id_plantilla} value={team.id_plantilla}>
                        {team.nombre_plantilla}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="datePicker">
                  <Form.Label>Seleccione una fecha</Form.Label><br />
                  <DatePicker
                    selected={date}
                    onChange={this.handleDateChange}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          {/* Training Report Table */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Dorsal</th>
                <th>Nombre</th>
                <th>Tipo de Entrenamiento</th>
                <th>RPE</th>
                <th>Tiempo (minutos)</th>
                <th>Player Load</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map(player => (
                <tr key={player.id_usuario}>
                  <td>{player.dorsal || 'N/A'}</td>
                  <td>{`${player.nombres_usuario} ${player.apellidos_usuario}`}</td>
                  <td>{player.tipo_entreno || 'N/A'}</td>
                  <td>
                    {player.rpe !== null ? (
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: this.getRpeColor(player.rpe),
                          marginRight: '5px',
                        }}
                      ></span>
                    ) : (
                      'N/A'
                    )}
                    {player.rpe !== null ? player.rpe : ''}
                  </td>
                  <td>{player.duracion !== null ? player.duracion : 'N/A'}</td>
                  <td>{player.carga !== null ? player.carga : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </MainLayout>
    );
  }
}

export default TrainingReport;
