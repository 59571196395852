import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SidebarNavigation from './SidebarNavigation';
import { UserContext } from '../../UserContext'; // Import UserContext
import './MainLayout.css'; // Custom styles for layout
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from '../../withRouter'; // Import withRouter helper

class MainLayout extends Component {
  static contextType = UserContext; // Attach the context to the class component

  handleLogout = () => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('username');
    localStorage.removeItem('rol');
    this.props.navigate('/login');
  };

  render() {
    const { username } = this.context; // Access username from context
    return (
      // set background color to black 
      <Container fluid className="bg-dark">
        <Row>
          {/* Sidebar Navigation */}
          <Col md={2}>
            <SidebarNavigation />
          </Col>

          {/* Main Content */}
          <Col md={10} className="main-content bg-secondary p-0">
            <header className="header bg-dark text-light d-flex justify-content-between align-items-center p-3">
              <h3 className="mb-0 text-end"><FontAwesomeIcon icon={faUser} className="menu-icon" /> Welcome, {username}</h3>
              <Button variant="outline-light" onClick={this.handleLogout}>
                Logout
              </Button>
            </header>

            {/* Render child content */}
            <div className="content p-4">
              {this.props.children}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(MainLayout);