import React from 'react';
import { Button, ButtonGroup, Form, Container, Row, Col } from 'react-bootstrap';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext';
import axios from 'axios';
import pee from '../images/dh.jpg';

class WellnessForm extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      responses: {
        preTrainingMood: null,
        sleepQuality: null,
        muscleFatigue: null,
        stressLevel: null,
        musclePain: null,
      },
      hydrationLevel: null, // Separate hydration level question
      weight: '',
      date: new Date().toISOString().split('T')[0],
    };
  }

  handleResponseChange = (question, value) => {
    this.setState(prevState => ({
      responses: {
        ...prevState.responses,
        [question]: value,
      },
    }));
  };

  handleHydrationChange = (value) => {
    this.setState({ hydrationLevel: value });
  };

  handleWeightChange = (event) => {
    this.setState({ weight: event.target.value });
  };

  handleSubmit = async () => {
    const { username } = this.context;
    const { responses, hydrationLevel, weight, date } = this.state;

    // Constructing the payload
    const payload = {
      id_usuario: username,
      date: date,
      weight: weight,
      hydrationLevel: hydrationLevel,
      ...responses,
    };

    try {
      // Sending the POST request to the backend
      const response = await axios.post('/api/wellness/add', payload);
      console.log('Response:', response.data);
      alert('Datos enviados correctamente');
      
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Por favor revisa la información e intenta de nuevo');
    }
  };

  getColorForValue = (value) => {
    const colors = ["#ff0000", "#ff7f00", "#ffff00", "#7fff00", "#00ff00"]; // Red to Green for general questions
    return colors[value - 1];
  };

  getHydrationColor = (value) => {
    // Colors based on the provided hydration chart
    const hydrationColors = ["#5b3b00","#5b3b00","#e2a300","#e4e500", "#e4e500"]; // Hydrated, Dehydrated, Severely Dehydrated
    return hydrationColors[value - 1];
  };

  render() {
    const { username } = this.context;
    const { weight, responses, hydrationLevel } = this.state;

    const questions = [
      {
        key: 'preTrainingMood',
        label: '¿Cómo te sientes antes del entrenamiento?',
        options: [
          { value: 1, label: 'Triste' },
          { value: 2, label: 'Desmotivado' },
          { value: 3, label: 'Normal' },
          { value: 4, label: 'Motivado' },
          { value: 5, label: 'Alegre' },
        ],
      },
      {
        key: 'sleepQuality',
        label: '¿Cómo calificas la calidad de tu sueño?',
        options: [
          { value: 1, label: 'Muy Malo' },
          { value: 2, label: 'Malo' },
          { value: 3, label: 'Regular' },
          { value: 4, label: 'Bueno' },
          { value: 5, label: 'Muy Bueno' },
        ],
      },
      {
        key: 'stressLevel',
        label: '¿Cuál es tu nivel de estrés?',
        options: [
          { value: 5, label: 'Muy bajo' },
          { value: 4, label: 'Bajo' },
          { value: 3, label: 'Normal' },
          { value: 2, label: 'Alto' },
          { value: 1, label: 'Muy alto' },
        ],
      },
      {
        key: 'muscleFatigue',
        label: '¿Cuál es tu nivel de fatiga muscular?',
        options: [
          { value: 5, label: 'Muy bajo' },
          { value: 4, label: 'Bajo' },
          { value: 3, label: 'Normal' },
          { value: 2, label: 'Alto' },
          { value: 1, label: 'Muy alto' },
        ],
      },
      {
        key: 'musclePain',
        label: '¿Qué tanto dolor muscular tienes?',
        options: [
          { value: 5, label: 'Muy bajo' },
          { value: 4, label: 'Bajo' },
          { value: 3, label: 'Normal' },
          { value: 2, label: 'Alto' },
          { value: 1, label: 'Muy alto' },
        ],
      },
    ];

    return (
      <MainLayout>
        <Container>
          <h4>Hola, {username}</h4>
          <p>Por favor ingresa tus datos de Bienestar para este día</p>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={6}>Peso (kg):</Form.Label>
            <Col sm={6}>
              <Form.Select
                value={weight}
                onChange={this.handleWeightChange}
              >
                <option value="">Selecciona tu peso</option>
                {Array.from({ length: 106 }, (_, i) => 45 + i).map(value => (
                  <option key={value} value={value}>{value} kg</option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
          
          {questions.map(question => (
            <Form.Group key={question.key} as={Row} className="mb-3">
              <Form.Label column sm={6} className='text-end'>{question.label}</Form.Label>
              <Col sm={6}>
                <ButtonGroup>
                  {question.options.map(option => (
                    <Button
                      key={option.value}
                      variant={responses[question.key] === option.value ? 'light' : 'outline-light'}
                      onClick={() => this.handleResponseChange(question.key, option.value)}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: this.getColorForValue(option.value),
                          marginRight: '5px',
                        }}
                      ></span>
                      {option.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </Col>
            </Form.Group>
          ))}

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={6} className='text-end'>¿Qué nivel de hidratación tienes hoy, según el color de tu orina?</Form.Label>
            <Col sm={6}>
              <ButtonGroup>
                {[{ value: 5, label: 'Hidratado' }, { value: 3, label: 'Deshidratado' }, { value: 1, label: 'Severamente Deshidratado' }].map(option => (
                  <Button
                    key={option.value}
                    variant={hydrationLevel === option.value ? 'light' : 'outline-light'}
                    onClick={() => this.handleHydrationChange(option.value)}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getHydrationColor(option.value),
                        marginRight: '5px',
                      }}
                    ></span>
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
              <br />
              <img src={pee} alt="Orina" width={100 + "%"} className='mt-1' />
            </Col>
          </Form.Group>

        <Form.Group as={Row} className="mt-5 mb-3 text-end">
          <Button variant="success" onClick={this.handleSubmit}>Enviar</Button>
        </Form.Group>
          
        </Container>
      </MainLayout>
    );
  }
}

export default WellnessForm;
