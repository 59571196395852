import React from 'react';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext'; // Import the UserContext

class Sesiones extends React.Component {
  static contextType = UserContext; // Attach the UserContext to this component

  render() {
    const { username } = this.context; // Access username from the context
    return (
      <MainLayout>
        {/* Specific content for Home */}
        <h4>Hola, {username}</h4> {/* Display the dynamic username */}
        <p>Aquí puedes programar las sesiones de entrenamiento</p>
      </MainLayout>
    );
  }
}

export default Sesiones;