import React, { useState, useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faHeartbeat,
  faDumbbell,
  faFileAlt,
  faClipboardList,
  faInfoCircle,
  faCalendar,
  faThLarge,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import './SidebarNavigation.css'; // Custom styles for the sidebar
import logCDFAS from '../../images/FAS.png';
import { UserContext } from '../../UserContext'; // Import your UserContext

const SidebarNavigation = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { rol } = useContext(UserContext); // Access rol from context
  console.log('Rol:', rol);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  return (
    <div>
      {/* Hamburger Button (only visible on small screens) */}
      <div className="hamburger-menu bg-dark text-light d-lg-none" onClick={toggleMenu}>
        <span>☰</span>
        <span className="ml-2">Menu</span>
      </div>

      {/* Sidebar (always visible on large screens) */}
      <div className={`sidebar bg-dark ${isMenuOpen ? 'open' : ''}`}>
        <div className="logo">
          <img src={logCDFAS} alt="FAS" width={64} />
          <span className="text-light"> - CD FAS Team Wellness</span>
        </div>
        <Nav className="flex-column">
          {/* Menu items visible to all roles */}
          <Nav.Link href="/home" className="text-light">
            <FontAwesomeIcon icon={faHome} className="menu-icon" /> Home
          </Nav.Link>
          <Nav.Link href="/wellness-form" className="text-light">
            <FontAwesomeIcon icon={faHeartbeat} className="menu-icon" /> Wellness
          </Nav.Link>
          <Nav.Link href="/training-form" className="text-light">
            <FontAwesomeIcon icon={faDumbbell} className="menu-icon" /> RPE
          </Nav.Link>

          {/* Menu items visible only if rol is not 3 */}
          {rol !== 3 && (
            <>
              <Nav.Link href="/wellness-report" className="text-light">
                <FontAwesomeIcon icon={faFileAlt} className="menu-icon" /> Reporte Wellness
              </Nav.Link>
              <Nav.Link href="/training-report" className="text-light">
                <FontAwesomeIcon icon={faClipboardList} className="menu-icon" /> Reporte RPE
              </Nav.Link>
              <Nav.Link href="/jugadores" className="text-light">
                <FontAwesomeIcon icon={faInfoCircle} className="menu-icon" /> Jugadores
              </Nav.Link>
              <Nav.Link href="/sesiones" className="text-light">
                <FontAwesomeIcon icon={faCalendar} className="menu-icon" /> Sesiones
              </Nav.Link>
              <Nav.Link href="/plantillas" className="text-light">
                <FontAwesomeIcon icon={faThLarge} className="menu-icon" /> Plantillas
              </Nav.Link>
              <Nav.Link href="/estadisticas" className="text-light">
                <FontAwesomeIcon icon={faChartBar} className="menu-icon" /> Estadísticas
              </Nav.Link>
            </>
          )}
        </Nav>
      </div>
    </div>
  );
};

export default SidebarNavigation;