import React, { Component } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { withRouter } from '../withRouter'; // Import withRouter helper
import { UserContext } from '../UserContext'; // Import UserContext for updating the username
import logCDFAS from '../images/FAS.png';


class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, password } = this.state;
    const { updateUser } = this.context;

    if (!username || !password) {
      this.setState({ error: 'Please fill in both fields.' });
      return;
    }

    try {
      const response = await fetch('/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Login successful:', data);
        localStorage.setItem('auth-token', data.token);
        localStorage.setItem('rol', data.rol);
        updateUser(username,data.rol); // Update the username in context
        this.props.navigate('/home'); // Redirect to Home
      } else {
        this.setState({ error: data.message || 'Login failed.' });
      }
    } catch (error) {
      this.setState({ error: 'An error occurred. Please try again later.' });
      console.error('Error during login:', error);
    }
  };

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center mt-5">
          <Col md={4}>
            <div className="logo">
            <img src={logCDFAS} alt="FAS" width={64} />
            <h2 className="text-center">CD FAS Wellness App Login</h2>
            <h5>Registro de datos de bienestar de jugadores de Club Deportivo FAS</h5>
            </div>
            
            {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="username"
                  value={this.state.username}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Login);