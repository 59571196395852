import React from 'react';
import axios from 'axios';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext';
import { Form, Button, Modal, Container, Row, Col, Table } from 'react-bootstrap';
import PlayerUpdateModal from './PlayerUpdateModal';
import PlayerCreateModal from './PlayerCreateModal';

class Players extends React.Component {
  static contextType = UserContext;

  state = {
    players: [],
    showCreateModal: false,
    showDeleteModal: false,
    playerToDelete: null,
    username: '',
    password: '',
    rol: '3',
    nombres: '',
    apellidos: '',
    estatura: '',
    peso: '',
    fecha_nacimiento: '',
    nacionalidad: '',
    posicion: '',
    error: null,
    filter: '',
    showPassword: false,
    showUpdateModal: false,
    playerToUpdate: null,
  };


  loadPlayers = () => {
    const token = localStorage.getItem('auth-token');
    axios.get('/api/roster/players/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        this.setState({ players: response.data.data });
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to fetch players' });
      });
  };

  componentDidMount() {
    this.loadPlayers();
  }

  toggleCreateModal = () => {
    this.setState({ showCreateModal: !this.state.showCreateModal });
  };
  toggleDeleteModal = (player = null) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal, playerToDelete: player });
  };

  toggleUpdateModal = (player = null) => {
    const formatDateForInput = (isoDate) => {
      if (!isoDate) return '';
      const date = new Date(isoDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };


    if (player) {
      this.setState({
        showUpdateModal: true,
        playerToUpdate: {
          ...player,
          fecha_nacimiento: formatDateForInput(player.fecha_nacimiento),
        },
      });
    } else {
      this.setState({ showUpdateModal: false, playerToUpdate: null });
    }

  };

  handleUpdatePlayer = (event) => {
    event.preventDefault();
    const { playerToUpdate } = this.state;

    
    const token = localStorage.getItem('auth-token');
    axios
      .post(`/api/roster/players/update/${playerToUpdate.id_usuario}`, playerToUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.setState({ showUpdateModal: false });
        this.loadPlayers();
      })
      .catch((error) => {
        console.error('Failed to update player', error);
      });
  };

  handleDeletePlayer = () => {
    const { playerToDelete } = this.state;
    const token = localStorage.getItem('auth-token');
    axios
      .delete(`/api/roster/players/${playerToDelete.id_usuario}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.setState({ showDeleteModal: false, playerToDelete: null });
        this.loadPlayers();
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to delete player' });
      });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleCreatePlayer = (event) => {
    event.preventDefault();
    const { username, password, rol, nombres, apellidos, estatura, peso, fecha_nacimiento, nacionalidad, posicion, players } = this.state;
    const token = localStorage.getItem('auth-token');

    if (!username || !password || !nombres || !apellidos || !estatura || !fecha_nacimiento || !nacionalidad) {
      alert('Please fill in all fields.');
      return;
    }

    axios.post('/api/roster/players/add', 
      { username, password, rol, nombres, apellidos, estatura, peso,fecha_nacimiento, nacionalidad,posicion },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.setState({ showCreateModal: false, error: null });
        this.loadPlayers();
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to create player' });
      });
  };

  handleFilterChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  getFilteredPlayers() {
    const { players, filter } = this.state;
    const lowercasedFilter = filter.toLowerCase();
    return players.filter((player) =>
      player.username.toLowerCase().includes(lowercasedFilter) ||
      player.nombres_usuario.toLowerCase().includes(lowercasedFilter) ||
      player.apellidos_usuario.toLowerCase().includes(lowercasedFilter) ||
      player.nacionalidad_usuario.toLowerCase().includes(lowercasedFilter)
    );
  }

  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  getPosicion = (posicion) => {
    switch (posicion) {
      case 0:
        return 'Portero';
      case 1:
        return 'Defensa';
      case 2:
        return 'Mediocampista';
      case 3:
        return 'Delantero';
      default:
        return 'Desconocido';
    }
  }

  getEdad = (fecha_nacimiento) => {
    const today = new Date();
    const birthDate = new Date(fecha_nacimiento);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  

  render() {
    const { username } = this.context;
    const { showCreateModal,showDeleteModal, playerToDelete, filter, username: playerUsername, password, rol, nombres, apellidos, estatura, peso, fecha_nacimiento, nacionalidad, posicion, error, showPassword } = this.state;

    const countries = [
      { name: 'Argentina', code: 'ARG' },
      { name: 'Brasil', code: 'BRA' },
      { name: 'Uruguay', code: 'URU' },
      { name: 'Panama', code: 'PAN' },
      { name: 'Guatemala', code: 'GUA' },
      { name: 'Ecuador', code: 'ECU' },
      { name: 'Mexico', code: 'MEX' },
      { name: 'El Salvador', code: 'SLV' },
      { name: 'Colombia', code: 'COL' },
      { name: 'United States', code: 'USA' },
      { name: 'Chile', code: 'CHI' },
      { name: 'Perú', code: 'PER' },
      { name: 'Venezuela', code: 'VEN' },
      { name: 'Costa Rica', code: 'CRC' },
      { name: 'Paraguay', code: 'PAR' },
      { name: 'Bolivia', code: 'BOL' },
      { name: 'Nicaragua', code: 'NCA' },
      { name: 'Honduras', code: 'HON' },
      { name: 'Jamaica', code: 'JAM' },
      { name: 'Trinidad & Tobago', code: 'TYT' },
      // Add more countries as needed
    ];

    const positions = [
      { name: 'Portero', code: 0 },
      { name: 'Defensa', code: 1 },
      { name: 'Mediocampista', code: 2 },
      { name: 'Delantero', code: 3 },
    ];

    const roles = [
      { name: 'Administrador', code: 0 },
      { name: 'Entrenaddor', code: 1 },
      { name: 'Jugador', code: 2 },
    ];

    const filteredPlayers = this.getFilteredPlayers();

    return (
      <MainLayout>
        <h4>Bienvenido, {username}</h4>
        <p>Administra los jugadores de tus equipo</p>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <Container>
          <Row className="mt-3">
            <Col md={12}>
              <Form.Group controlId="formFilter">
                <Form.Label>Filtrar Jugadores</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Filtrar por nombre, apellido o nacionalidad"
                  value={filter}
                  onChange={this.handleFilterChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Login</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Estatura (cm)</th>
                    <th>Peso (kg)</th>
                    <th>Fecha de Nacimiento</th>
                    <th>Nacionalidad</th>
                    <th>Posición</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPlayers.map((player) => (
                    <tr key={player.id_jugador}>
                      <td>{player.username}</td>
                      <td>{player.nombres_usuario}</td>
                      <td>{player.apellidos_usuario}</td>
                      <td>{player.estatura_usuario}</td>
                      <td>{player.peso_usuario}</td>
                      <td>{new Date(player.fecha_nacimiento).toLocaleDateString()} - {this.getEdad(new Date(player.fecha_nacimiento))} Años</td>
                      <td>{player.nacionalidad_usuario}</td>
                      <td>{this.getPosicion(player.posicion)}</td>
                      <td>
                        <Button variant="danger" onClick={() => this.toggleDeleteModal(player)}>
                          Eliminar
                        </Button>
                        <Button variant="warning" onClick={() => this.toggleUpdateModal(player)}>
                          Editar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Button variant="success" className="mt-4" onClick={this.toggleCreateModal}>
                Crear Nuevo Jugador
              </Button>
            </Col>
          </Row>
        </Container>

        {/* Modal for creating a new player */}
        <PlayerCreateModal
          show={showCreateModal}
          onHide={this.toggleCreateModal}
          onSubmit={this.handleCreatePlayer}
          onInputChange={this.handleInputChange}
          playerData={{
            username: playerUsername,
            password,
            nombres,
            apellidos,
            estatura,
            peso,
            fecha_nacimiento,
            nacionalidad,
            posicion,
          }}
          positions={positions}
          countries={countries}
          showPassword={showPassword}
          toggleShowPassword={this.toggleShowPassword}
        />


        <Modal show={showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Eliminación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {playerToDelete && (
              <p>
                ¿Estás seguro que deseas eliminar al jugador <b>{playerToDelete.nombres_usuario} {playerToDelete.apellidos_usuario}</b>?
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleDeleteModal}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.handleDeletePlayer}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>

        <PlayerUpdateModal
          show={this.state.showUpdateModal}
          onHide={this.toggleUpdateModal}
          onSubmit={this.handleUpdatePlayer}
          playerData={this.state.playerToUpdate || {}}
          positions={positions}
          countries={countries}
          onInputChange={this.handleInputChange}
          showPassword={this.state.showPassword}
          toggleShowPassword={this.toggleShowPassword}
        />
      </MainLayout>
    );
  }
}

export default Players;
