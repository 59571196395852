import React from 'react';
import axios from 'axios';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext';
import { Form, Button, Dropdown, Modal, Container, Row, Col, Table } from 'react-bootstrap';
import AssignPlayerModal from './AssignPlayerModal';

class Plantillas extends React.Component {
  static contextType = UserContext;
  
  state = {
    username: '',
    teams: [], // Array to store existing teams
    selectedTeam: null, // Currently selected team for editing
    showCreateModal: false, // Controls visibility of the modal
    id_plantilla: '', // New team ID
    nombre_plantilla: '', // New team name
    error: null,
    players: [],
    showAssignPlayerModal: false,
    editingDorsal: {}
  };

 
  toggleAssignPlayerModal = () => {
    this.setState({ showAssignPlayerModal: !this.state.showAssignPlayerModal });
  };

  componentDidMount() {
    const token = localStorage.getItem('auth-token');
    axios.get('/api/roster/teams', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        this.setState({ teams: response.data.data });
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to fetch teams' });
      });
  }

  toggleCreateModal = () => {
    this.setState({ showCreateModal: !this.state.showCreateModal });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleCreateTeam = (event) => {
    event.preventDefault();
    const { nombre_plantilla, teams } = this.state;
    const token = localStorage.getItem('auth-token');
  
    if (nombre_plantilla.trim() === '') {
      alert('Please enter a team name.');
      return;
    }
  
    axios.post('/api/roster/teams', 
      { nombre_plantilla },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const newTeam = response.data;
        this.setState({
          teams: [...teams, newTeam],
          nombre_plantilla: '',
          showCreateModal: false,
        });
      })
      .catch((error) => {
        this.setState({ error: error.response ? error.response.data.message : 'Failed to create team' });
      });
  };

  handleRemovePlayer = (playerId) => {
    const { selectedTeam } = this.state;
    const token = localStorage.getItem('auth-token');

    axios.delete(`/api/roster/teams/${selectedTeam.id_plantilla}/remove-player/${playerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => {
      alert('Player removed from team successfully.');
      this.handleSelectTeam(selectedTeam); // Refresh the team data
    })
    .catch((error) => {
      this.setState({ error: error.response ? error.response.data.message : 'Failed to remove player' });
    });
  };
  handleEditDorsal = (playerId) => {
    this.setState((prevState) => ({
      editingDorsal: { ...prevState.editingDorsal, [playerId]: true },
    }));
  };
  handleDorsalChange = (playerId, value) => {
    this.setState((prevState) => ({
      players: prevState.players.map((player) =>
        player.id_usuario === playerId ? { ...player, dorsal: value } : player
      ),
    }));
  };

  handleSaveDorsal = (playerId) => {
    const { selectedTeam, players } = this.state;
    const token = localStorage.getItem('auth-token');
    const player = players.find((p) => p.id_usuario === playerId);
  
    axios.post(`/api/roster/teams/update-number/${selectedTeam.id_plantilla}/${playerId}`, 
      { dorsal: player.dorsal },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(() => {
      this.setState((prevState) => ({
        editingDorsal: { ...prevState.editingDorsal, [playerId]: false },
      }));
      alert('Dorsal updated successfully.');
    })
    .catch((error) => {
      this.setState({ error: error.response ? error.response.data.message : 'Failed to update dorsal' });
    });
  };


  handleSelectTeam = (team) => {
    this.setState({ selectedTeam: team }, () => {
      // Load team data for the selected
      const token = localStorage.getItem('auth-token');
      axios.get(`/api/roster/plantillas/${team.id_plantilla}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          this.setState({ players: response.data.data });
        })
        .catch((error) => {
          this.setState({ error: error.response ? error.response.data.message : 'Failed to fetch team data' });
        });





    });
  };
  getPosicion = (posicion) => {
    switch (posicion) {
      case 0:
        return 'Portero';
      case 1:
        return 'Defensa';
      case 2:
        return 'Mediocampista';
      case 3:
        return 'Delantero';
      default:
        return 'Desconocido';
    }
  }

  render() {



    const { username } = this.context;
    const { teams, selectedTeam, showCreateModal, id_plantilla, nombre_plantilla, error,showAssignPlayerModal,editingDorsal } = this.state;

    return (
      <MainLayout>
        <h4>Bienvenido, {username}</h4>
        <p>Administra las plantillas de cada equipo según la temporada que corresponda</p>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        {/* Dropdown to select a team to edit */}
        <Container>
          <Row className="mt-5">
            <Col md={6}>
              <h5>Selecciona un equipo</h5>
              <Dropdown onSelect={(teamIndex) => this.handleSelectTeam(teams[teamIndex])}>
                <Dropdown.Toggle variant="primary">
                  {selectedTeam ? selectedTeam.nombre_plantilla : 'Selecciona un equipo'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {teams.map((team, index) => (
                    <Dropdown.Item key={team.id_plantilla} eventKey={index}>
                      {team.nombre_plantilla}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {selectedTeam && (
                <div className="mt-4">
                  <h6>Equipo seleccionado</h6>
                  <p><strong>ID:</strong> {selectedTeam.id_plantilla}</p>
                  <p><strong>Nombre:</strong> {selectedTeam.nombre_plantilla}</p>
                </div>
              )}
              
              <Button variant="primary" className="mt-3" onClick={this.toggleAssignPlayerModal}>
                  Buscar y agregar jugador
                </Button>


              <Row className="mt-3">
            <Col md={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Posición</th>
                    <th>Dorsal</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.players.map((player) => (
                    <tr key={player.id_usuario}>
                      <td>{player.id_usuario}</td>
                      <td>{player.apellidos_usuario}, {player.nombres_usuario} </td>
                      <td>{this.getPosicion(player.posicion)}</td>
                      <td>
                          {editingDorsal[player.id_usuario] ? (
                            <Form.Control
                              type="number"
                              value={player.dorsal}
                              onChange={(e) => this.handleDorsalChange(player.id_usuario, e.target.value)}
                            />
                          ) : (
                            player.dorsal
                          )}
                        </td>
                        <td>
                        {editingDorsal[player.id_usuario] ? (
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => this.handleSaveDorsal(player.id_usuario)}
                          >
                            Guardar
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => this.handleEditDorsal(player.id_usuario)}
                          >
                            Editar
                          </Button>
                        )}
                        <Button 
                          variant="danger" 
                          size="sm" 
                          className="ms-2"
                          onClick={() => this.handleRemovePlayer(player.id_usuario)}
                        >
                          -
                        </Button>
                      </td>
                 
                    </tr>
                  ))}
                  </tbody>
              </Table>
            </Col>
          </Row>

              <Button variant="success" className="mt-4" onClick={this.toggleCreateModal}>
                Crear nuevo equipo
              </Button>
            </Col>
          </Row>
        </Container>

        {/* Modal for creating a new team */}
        <Modal show={showCreateModal} onHide={this.toggleCreateModal}>
          <Modal.Header closeButton>
            <Modal.Title>Crear nuevo equipo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleCreateTeam}>
              <Form.Group controlId="formNombrePlantilla" className="mt-3">
                <Form.Label>Nombre del equipo:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese nombre de equipo"
                  name="nombre_plantilla"
                  value={nombre_plantilla}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-3">
                Agregar equipo
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <AssignPlayerModal
        show={showAssignPlayerModal}
        onHide={this.toggleAssignPlayerModal}
        selectedTeam={selectedTeam}
        onPlayerAssigned={() => this.handleSelectTeam(selectedTeam)}
      />
      </MainLayout>
    );
  }
}

export default Plantillas;
