import React from 'react';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext'; // Import the UserContext
import campeones from '../images/fas-campeon-apertura-2022-futbol.jpg';
class Home extends React.Component {
  static contextType = UserContext; // Attach the UserContext to this component
  state = {
    username: '',
    dashboardData: null, // State to hold the dashboard data
    error: null,
  };

  componentDidMount() {
    // Fetch the dashboard data from the /dashboard endpoint
    const token = localStorage.getItem('auth-token'); // Get the auth token from localStorage
    fetch('/api/wellness/dashboard', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Pass the token for authentication
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch dashboard data');
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in the state after fetching
        this.setState({ dashboardData: data });
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });
  }

  render() {
    const { username, rol } = this.context; // Access username from the context
    const { dashboardData, error } = this.state;

    return (
      <MainLayout>
        {/* Specific content for Home */}
        <h4>Bienvenido, {username}</h4> {/* Display the dynamic username */}
        {rol < 3 && <p>¡Bienvenido al panel de administración!</p>} {/* Display admin message for roles < 3 */}
        {rol >= 3 && <p>¡Bienvenido! Esta herramienta nos ayudará a controlar tu bienestar y que puedas dar tu máximo potencial</p>} {/* Display user message for roles >= 3 */}


        {/* Display dashboard data */}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {dashboardData ? (
          <div>
            <h5>Dashboard Data:</h5>
            <img src={campeones} />
          </div>
        ) : (
          <p>Loading dashboard data...</p>
        )}
      </MainLayout>
    );
  }
}

export default Home;