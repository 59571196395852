import React from 'react';
import { Navigate } from 'react-router-dom';

// This component checks if the user is logged in. If not, it redirects to /login.
const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('auth-token'); // Check if auth token exists

  return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
