import React from 'react';
import { Button, ButtonGroup, Form, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import MainLayout from './Layouts/MainLayout';
import { UserContext } from '../UserContext';

class TrainingForm extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      sessionType: null,
      rpe: null,
      duration: 1, // default value for duration
      date: new Date().toISOString().split('T')[0],
    };
  }

  handleSessionTypeChange = (value) => {
    this.setState({ sessionType: value });
  };

  handleRpeChange = (value) => {
    this.setState({ rpe: value });
  };

  handleDurationChange = (event) => {
    this.setState({ duration: event.target.value });
  };

  handleSubmit = async () => {
    const { username } = this.context;
    const { sessionType, rpe, duration, date } = this.state;

    // Payload for backend
    const payload = {
      id_usuario: username,
      date: date,
      sessionType: sessionType,
      rpe: rpe,
      duration: duration,
    };

    try {
      const response = await axios.post('/api/training/add', payload);
      console.log('Response:', response.data);
      alert('Data submitted successfully!');
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('There was an error submitting your data. Please try again.');
    }
  };

  getRpeColor = (value) => {
    const colors = [
      "#00ff00", "#7fff00", "#bfff00", "#ffff00", "#ffbf00", "#ff8000",
      "#ff4000", "#ff0000", "#bf0000", "#800000"
    ]; // Green to Red
    return colors[value - 1];
  };

  render() {
    const { username } = this.context;
    const { sessionType, rpe, duration } = this.state;

    const sessionTypes = [
      "Coadyuvante", "Entrenamiento", "Re-Adaptación", "Rehabilitación", "Partido", "Amistoso"
    ];

    const rpeLevels = [
      { value: 1, label: "Muy Muy Suave" },
      { value: 2, label: "Muy Suave" },
      { value: 3, label: "Bastante Fácil" },
      { value: 4, label: "Fácil" },
      { value: 5, label: "Normal" },
      { value: 6, label: "Poco Alto" },
      { value: 7, label: "Alto" },
      { value: 8, label: "Muy Alto" },
      { value: 9, label: "Fatigado" },
      { value: 10, label: "Muy Fatigado" },
    ];

    const durationOptions = Array.from({ length: 180 }, (_, i) => i + 1); // 1 to 180

    return (
      <MainLayout>
        <Container>
          <h4>Hola, {username}</h4>
          <p>Por favor registra los detalles de tu última sesión de entrenamiento</p>

          {/* Session Type */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className='text-end'>Tipo de sesión:</Form.Label>
            <Col sm={8}>
              <ButtonGroup>
                {sessionTypes.map((type, index) => (
                  <Button
                    key={index}
                    variant={sessionType === type ? 'light' : 'outline-light'}
                    onClick={() => this.handleSessionTypeChange(type)}
                  >
                    {type}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Form.Group>

          {/* RPE (Intensity Level) */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className='text-end' >RPE (Nivel de intensidad):</Form.Label>
            <Col sm={8}>
              <ButtonGroup>
                {rpeLevels.map(option => (
                  <Button
                    key={option.value}
                    variant={rpe === option.value ? 'light' : 'outline-light'}
                    onClick={() => this.handleRpeChange(option.value)}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: this.getRpeColor(option.value),
                        marginRight: '5px',
                      }}
                    ></span>
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Form.Group>

          {/* Duration */}
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4} className='text-end' >Duración (minutos):</Form.Label>
            <Col sm={8}>
              <Form.Control as="select" value={duration} onChange={this.handleDurationChange}>
                {durationOptions.map(option => (
                  <option key={option} value={option}>
                    {option} minutos
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mt-5 mb-3 text-end">
            <Button variant="success" onClick={this.handleSubmit}>Enviar</Button>
          </Form.Group>

          
        </Container>
      </MainLayout>
    );
  }
}

export default TrainingForm;