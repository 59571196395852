import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import WellnessForm from './components/WellnessForm';
import Login from './components/Login'; // Import Login component
import { UserProvider } from './UserContext'; // Import UserProvider
import PrivateRoute from './components/Layouts/PrivateRoute'; // Import PrivateRoute component
import TrainingForm from './components/TrainingForm';
import WellnessReport from './components/WellnessReport';
import TrainingReport from './components/TrainingReport';
import Plantillas from './components/Plantillas';
import Players from './components/Players';
import Sesiones from './components/Sesiones';
import WellnessStats from './components/WellnessStats';

class App extends React.Component {
  render() {
    return (
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} /> {/* Public route */}
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/wellness-form"
              element={
                <PrivateRoute>
                  <WellnessForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/training-form"
              element={
                <PrivateRoute>
                  <TrainingForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/wellness-report"
              element={
                <PrivateRoute>
                  <WellnessReport />
                </PrivateRoute>
              }
            />            <Route
              path="/training-report"
              element={
                <PrivateRoute>
                  <TrainingReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/plantillas"
              element={
                <PrivateRoute>
                  <Plantillas />
                </PrivateRoute>
              }
            />
            <Route
              path="/jugadores"
              element={
                <PrivateRoute>
                  <Players />
                </PrivateRoute>
              }
            />
            <Route
              path="/estadisticas"
              element={
                <PrivateRoute>
                  <WellnessStats />
                </PrivateRoute>
              }
            />
            <Route
              path="/sesiones"
              element={
                <PrivateRoute>
                  <Sesiones />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<Login />} /> {/* Default route */}
          </Routes>
        </Router>
      </UserProvider>
    );
  }
}

export default App;